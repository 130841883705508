<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<el-table
			    :data="list"
				:span-method="arraySpanMethod"
			    border
			    style="width: 100%">
			    <el-table-column
			      prop="nianji"
			      label="年级"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="xueke"
			      label="学科"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="t_code"
				  align="center"
			      label="教师编码（饭卡号）">
			    </el-table-column>
				<el-table-column
				  prop="t_name"
				  align="center"
				  label="教师姓名">
				</el-table-column>
				<el-table-column
				  prop="aszd"
				  align="center"
				  label="奥赛指导(1分)">
				</el-table-column>
				<el-table-column
				  prop="un_aszd"
				  align="center"
				  label="非奥赛指导(1分)">
				</el-table-column>
				<el-table-column
				  prop="shsj"
				  align="center"
				  label="社会实践活动(1分)">
				</el-table-column>
				<el-table-column
				  prop="yjxx"
				  align="center"
				  label="研究性学习(1分)">
				</el-table-column>
				<el-table-column
				  prop="stzd"
				  align="center"
				  label="社团指导(1分)">
				</el-table-column>
				<el-table-column
				  prop="total"
				  align="center"
				  label="合计">
				</el-table-column>
			  </el-table>
		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'hdzd',
				autoRequest: false,
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				data:{},
				list:[],
				list_length:0,
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
			this.getData()
		},
		computed:{
			
		},
		methods:{
			getData(){
				this.axios.get('/manage/'+this.preUrl+'/view',{
					params:{
						id:this.id
					}
				}).then(res=>{
					if(res.status){
						this.data = res.data
						this.list = res.data.list
						this.list_length = this.list.length
					}
				})
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if(rowIndex === this.list_length){
					if(columnIndex === 0){
						return [2, 3];
					}
				}
				if(rowIndex === this.list_length+2){
					if(columnIndex === 0){
						return [1, 5];
					}
					if(columnIndex === 1){
						return [1, 7];
					}
				}
				if(rowIndex === this.list_length+3){
					if(columnIndex === 0){
						return [1, 20];
					}
				}
			},
		}
	}
</script>

<style>
</style>